// @flow
import React from "react";
import { Link } from "gatsby";

type Props = {
  id: string,
  title: string,
  date: string,
  body: string,
  slug?: string
};

export default ({ id, title, date, body, slug }: Props) => (
  <div key={id} class="row">
    <div class="col">
      <h3>{title}</h3>
      <p>
        <small>{date}</small>
      </p>
      <div dangerouslySetInnerHTML={{ __html: body }} />
      {slug && (
        <div class="d-flex justify-content-end">
          <Link to={slug}>
            <button class="btn btn-outline-primary">Read more</button>
          </Link>
        </div>
      )}
    </div>
  </div>
);
