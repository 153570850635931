import React from "react";
import Helmet from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

export default () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      const {
        site: {
          siteMetadata: {
            title,
          },
        },
      } = data;
      return (
        <nav class="navbar navbar-expand border-bottom border-light sticky-top justify-content-between">
          <Helmet>
            <title>{ title }</title>
          </Helmet>
          <span class="navbar-brand">{ title }</span>
          <div>
            <div class="d-md-none">
              <form class="form-inline">
                <button class="btn btn-primary">Try our study app</button>
              </form>
            </div>
            <div class="d-none d-md-flex">
              <form class="form-inline">
                <span class="navbar-text mr-4">
                  Study for technical coding interviews{" "}
                  <strong>effectively</strong>
                </span>
                <button class="btn btn-primary">Learn more</button>
              </form>
            </div>
          </div>
        </nav>
      );
    }}
  />
);
