// @flow
import React from "react";
import Layout from "../components/Layout";
import Post from "../components/Post";
import { graphql } from "gatsby";

export default ({ data }: { data: Object }) => {
  const { id, frontmatter, html } = data.markdownRemark;
  return (
    <Layout>
      <Post
        id={id}
        title={frontmatter.title}
        date={frontmatter.date}
        body={html}
      />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      id
      frontmatter {
        title
        date(formatString: "MMMM Do, YYYY")
      }
    }
  }
`;
