import React from 'react';
import Header from './Header';

export default ({ children }) => (
  <>
    <Header />
    <div class="container mt-5 mb-5">
      <div class="col col-lg-8">
        { children }
      </div>
    </div>
  </>
);